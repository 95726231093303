.bigStoneContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/svg/big-stone-shadow.svg");
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.8;
  filter: blur(100px);
  /* transform: rotate(-13.626deg); */
}
