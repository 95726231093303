@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/**
 * Reset styles
 */

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html,
body {
  height: 100%;
  min-width: 320px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

body {
  color: #000000;
  line-height: 1;
  font-size: 1rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  line-height: 1;
}

header {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}
